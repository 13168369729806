body {
    height: 100vh;
    max-height: 100vh;
    background-color: #eee;
    width: min(100vw, 100%);
    margin: 0;
    padding: 0;
    display: flex;
    overflow: hidden;
}

main {
    width: 100vw;
    max-height: 100vh;
    display: grid;
    grid-template-columns: [figure-satrt content-start] 1fr [figure-end] 1fr [content-end];
    grid-template-rows: 1fr;
    place-content: center;
    background-color: whitesmoke;

    & >  svg {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 1 / -1;
        z-index: 0;
        opacity: 0.2;
        min-height: min(174vh, 140%);
        max-width: min(100vw, 60ch);
        margin-inline-end: max(5rem, 30%);
        align-self: start;
        justify-self: end;
    }
}

section {
    display: grid;
    grid-column-start: content-start;
    grid-column-end: content-end;
    grid-row: 1 / -1;
    z-index: 1;
    width: min(100vw, 50ch);
    margin: auto;

    h1 {
        margin: 0;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        font-weight: 300;
        font-size: clamp(2rem, 1rem + 3vw, 3rem);
    }

    figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin-block-end: 1.5rem;
    }

    figcaption span {
        display: block;
        font-size: .5em;
    }

    picture {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background-color: white;
        overflow: hidden;
    }

    img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 1rem;
    }

    ul {
        display: flex;
        gap: clamp(1rem, 1rem + 2vw, 2rem);
        align-items: center;
        justify-content: center;
        padding: 0;
        list-style: none;

        a {
            display: flex;
            width: 3rem;
            height: 3rem;

            &>svg {
                width: 2.5rem;
                margin: auto;
            }
        }
    }
}

footer {
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 300;
}

.zero {
    stroke-dasharray: 1079 1081;
    stroke-dashoffset: 1080;
    animation: draw 3000ms 0ms forwards;
}

.one {
    animation: opacity-one 3000ms 0ms both;
}

.two {
    animation: opacity-two 3000ms 0ms both;
}